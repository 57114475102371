.coming-soon {
  background-color: #000;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.coming-soon-logo {
  width: 287px;
  height: auto;
}
.coming-soon-text {
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 110%;
  /* or 26px */

  letter-spacing: -0.04em;
  color: #ffffff;
}
